"use strict";

let burgerMenu = document.querySelector("#burgerMenu");
let header = document.querySelector(".header");

burgerMenu.addEventListener('click', event => {
    header.classList.toggle("header--opened");
});

let indexTitle = document.querySelector(".banner__introduction");
if(indexTitle){
    let indexTitleTextArray = indexTitle.textContent.split("");
    let indexTitleTextArrayLength = indexTitleTextArray.length;
    indexTitle.textContent = "";
    for(let i = 0; i < indexTitleTextArrayLength; i++){
        if(indexTitleTextArray[i] === "\n"){
            indexTitle.innerHTML += "<br>";
        }else if(indexTitleTextArray[i] !== " "){
            indexTitle.innerHTML += "<span class=\"animatedBanner\">" + indexTitleTextArray[i] + "</span>";
        }else{
            indexTitle.innerHTML += " ";
        }
        
    }
    let indexTitleLetters = document.querySelectorAll(".banner__introduction .animatedBanner");
    for(let i = 0; i < indexTitleLetters.length; i++){
        indexTitleLetters[i].style.animationDelay = (i * (indexTitleTextArrayLength * 0.03) )/indexTitleLetters.length + "s";
    }
}



// Calculatrice
let transition = document.querySelector(".calculatrice__menu");
if(transition){

    var contenu;

    transition.addEventListener('animationend', event => {
        transition.classList.remove("calculatrice__menu--transition");
        transition.classList.remove("calculatrice__menu--transitionBack");
    });
    
    let boutons = document.querySelectorAll('[data-button]');
    let tous_les_boutons = document.querySelector('.calculatrice__Allbuttons');
    let retour = document.querySelector(".calculatrice__back");
    let questions = document.querySelector("#question");

    let calculatriceOutput = document.querySelector(".calculatrice__output");


    let fetchUrl = window.location.href + ".json";

    let query = new Request (fetchUrl, {
        method: "GET",
        headers: {
        'X-Requested-With': 'expimmo_api'
        }
    });


    async function getContentJson(){
        let response = await fetch(query);
        contenu = await response.json();
        console.log(contenu);
        calculatrice("init", false);
    }
    getContentJson();
      
    
    let client = new Object();
    client = {
        "etape" : 0,
        "racine": null,
        "fin_branche": false,
        "choix": [],
    }

    // pour déclancher le retour en arrière
    retour.addEventListener('click', () => {
        calculatrice("retour", false);
    });

    // pour déclancher le passage à l'étape suivante
    boutons.forEach(boutons => {
        boutons.addEventListener('click', () => {
            let bouton_presse = boutons.getAttribute('data-button');
            calculatrice("suivant", bouton_presse);
        })
    });

    function calculatrice(action, bouton){
        
        if(action === "init"){
            questions.innerHTML = "De quel type de missions s'agit t'il ?";
            for(let j = 0; j < boutons.length; j++){
                boutons[j].classList.remove("calculatrice__button--hidden");
                if(contenu.length > j){
                    let reponse = contenu[j].racine;
                    boutons[j].innerHTML = reponse;
                }else{
                    boutons[j].classList.add("calculatrice__button--hidden");
                }
            }


        }else if(action === "retour" && client.etape > 0){
            transition.classList.add("calculatrice__menu--transitionBack");// on lance l'animation de retour en arrière
            if(transition.getAttribute("data-finished") !== "true"){
                client.choix = client.choix.slice(0, client.etape);
                 // on descend de 1 step
            }
            client.etape--; 
            
            transition.setAttribute("data-finished", "false");
            setTimeout(function(){
                if(client.etape === 0){
                    calculatrice("init", false);
                }else{
                    for(let i = 0; i < contenu.length; i++){    //on teste toutes les questions pour trouver dans quelle branche il est engagé
                        if(contenu[i].id === client.racine ){    // sera true si on est dans la branche racine
                            let etape = client.etape;
                            console.log(client);
                                for(let y = 0; y < boutons.length; y++){    // on boucle sur le nombre des boutons poour afficher les nouvelles réponses possibles
                                    boutons[y].classList.remove("calculatrice__button--hidden");
                                    if(contenu[i][etape - 1].reponses.length > y){
                                        let reponse = contenu[i][etape - 1].reponses[y].texte;
                                        boutons[y].innerHTML = reponse;
                                    }else{
                                        boutons[y].classList.add("calculatrice__button--hidden");
                                    }
                                }
                            questions.innerHTML = contenu[i][etape - 1].texte;
                        }
                    }
                }
            }, 500);
            

            for (let j = 0; j < client.choix.length; j++) {
                if(j >= client.step){
                    client.choix[j] = null;
                }
            }
            

        }else if(action === "suivant" && bouton){
            bouton = parseInt(bouton);
            //on teste si la réponse n'est pas finale
            
            if(client.etape === 0){

                client.racine = contenu[bouton].id; //on insère l'id de la question racine dans le client
                client.etape++;

                if(contenu[bouton].reponse_finale === true){
                    transition.setAttribute("data-finished", "true");
                    calculTotal();
                    for(let y = 0; y < boutons.length; y++){
                        boutons[y].classList.add("calculatrice__button--hidden");
                    }
                }else{
                    //on insère l'id de la question racine dans le client
                    transition.classList.add("calculatrice__menu--transition");
                    setTimeout(function(){ 
                        // on affiche la 1ère question de la branche
                        questions.innerHTML = contenu[bouton][0].texte;

                        //on affiche les réponses de la 1ère question
                        for(let i = 0; i < boutons.length; i++){
                            boutons[i].classList.remove("calculatrice__button--hidden");
                            if(contenu[bouton][0].reponses.length > i){
                                let reponse = contenu[bouton][0].reponses[i].texte;
                                boutons[i].innerHTML = reponse;
                            }else{
                                boutons[i].classList.add("calculatrice__button--hidden");
                            }
                        }
                    }, 500);
                }

            }else{
                client.choix[client.etape] = bouton;    // on insère le choix dans le client

                for(let i = 0; i < contenu.length; i++){    //on teste toutes les questions pour trouver dans quelle branche il est engagé
                    if(contenu[i].id === client.racine){    // sera true si on est dans la branche racine
                        let etape = client.etape;
                        if(contenu[i][etape - 1].reponses[bouton].reponse_finale === true){
                            //si la réponse est finale, on affiche le résultat
                            transition.setAttribute("data-finished", "true");
                            calculTotal();
                            for(let y = 0; y < boutons.length; y++){
                                boutons[y].classList.add("calculatrice__button--hidden");
                            }
                        }else{
                            transition.classList.add("calculatrice__menu--transition");
                            setTimeout(function(){ 
                                for(let y = 0; y < boutons.length; y++){    // on boucle sur le nombre des boutons poour afficher les nouvelles réponses possibles

                                    boutons[y].classList.remove("calculatrice__button--hidden");
                                    if(contenu[i][etape].reponses.length > y){
                                        let reponse = contenu[i][etape].reponses[y].texte;
                                        boutons[y].innerHTML = reponse;
                                        
                                    }else{
                                        boutons[y].classList.add("calculatrice__button--hidden");
                                    }
                                    
                                }
                                questions.innerHTML = contenu[i][etape].texte;
                            }, 500);
                            
                        }
                        client.etape++;
                        
                    }
                }
             
            }

        }
      //  console.log(client.choix)
     //   console.log(client);
    }

    function calculTotal(){
        console.log(client);
        let total = "x";
        for(let i = 0; i < contenu.length; i++){
            if(contenu[i].id === client.racine){
                total = contenu[i].base;
                for(let j = 1; j < client.choix.length; j++){
                 //   console.log("choixs : "+client.choix[i]);
                 let Montant = contenu[i][j - 1].reponses[client.choix[j]].montant;
                 let operation = contenu[i][j - 1].reponses[client.choix[j]].operation;

                 // console.log("total = "+ total + " |  operation : " + Operation + " | montant : " + Montant);

                 if(operation == "+"){
                        total = total + Montant;
                 }else if(operation == "*"){
                        total = total * Montant;
                 }else if(operation == "-"){
                        total = total - Montant;
                 }else if(operation == "+%"){
                        total = total + (total * Montant / 100);
                 }
                }
            }};

            if(total < 0){
                total = "Erreur";
            };
        calculatriceOutput.innerHTML = total + " €";
    }
    
}
